import { icons } from "src/assets/dist/svg/icons";
import { ThemeColor } from "./models/theme.model";

// Helper arrays
export const styleguideConfig: {
  themeExampleColors: ThemeColor[];
  styles: ThemeColor[];
  [key: string]: string[];
} = {
  themes: ["light", "dark", "nights"],
  themeExampleColors: ["primary", "secondary", "accent"],
  sizes: ["lg", "md", "sm", "xs"],
  colors: [
    "blue",
    "indigo",
    "purple",
    "pink",
    "red",
    "orange",
    "yellow",
    "green",
    "teal",
    "cyan",
    "gray",
  ],
  styles: [
    "primary",
    "secondary",
    "accent",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ],
  weights: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  icons: icons,
};
